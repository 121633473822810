<template>
    <div>
        <div class="course_back">
            <img :src="source.back_img" alt=""/>
        </div>
        <div class="course_mask"></div>
        <div class="course_header">
            <p>{{source.title}}</p>
            <p>{{source.subtitle}}</p>
        </div>
        <div style="background: #fff;border-radius: 20px;margin-bottom: 50px;">
            <div style="height: 20px;"></div>

            <van-cell>
                <template slot="title">
                    <span style="font-size: 20px;font-weight: bold;">课程介绍</span>
                    <img src="../../assets/img/clip.png" style="height: 14px;margin-left: 5px;" alt="">
                </template>
            </van-cell>
            <div style="padding: 0 20px;">
                <p style="font-size: 16px;line-height: 28px;">{{source.introduction}}</p>
            </div>


            <van-cell v-if="source.highlight_1">
                <template slot="title">
                    <span style="font-size: 20px;font-weight: bold;">课程亮点</span>
                    <img src="../../assets/img/clip.png" style="height: 14px;margin-left: 5px;" alt="">
                </template>
            </van-cell>
            <div class="highlights" v-if="source.highlight_1">
                <div>
                    <div>
                        <img src="../../assets/img/course/highlights_1.png" alt="" />
                        <div><p>{{source.highlight_1}}</p></div>
                    </div>
                </div>
                <div v-if="source.highlight_2">
                    <div>
                        <img src="../../assets/img/course/highlights_2.png" alt="" />
                        <div><p>{{source.highlight_2}}</p></div>
                    </div>
                </div>
                <div v-if="source.highlight_3">
                    <div>
                        <img src="../../assets/img/course/highlights_3.png" alt="" />
                        <div><p>{{source.highlight_3}}</p></div>
                    </div>
                </div>
            </div>


            <van-cell>
                <template slot="title">
                    <span style="font-size: 20px;font-weight: bold;">大咖讲师</span>
                    <img src="../../assets/img/clip.png" style="height: 14px;margin-left: 5px;" alt="">
                </template>
            </van-cell>
            <div class="teacher">
                <div>
                    <div>
                        <p>小菲</p>
                        <p>来飞网金牌讲师</p>
                    </div>
                    <div>
                        <img src="../../assets/img/course/teacher.png" alt=""/>
                    </div>
                </div>
                <div>
                    <ul>
                        <li>
                            <span></span>
                            <p>曾先后就职于百度外卖、美团点评等互联网巨头</p>
                        </li>
                        <li>
                            <span></span>
                            <p>头条号专栏作家，多篇关于内容营销的文章被疯转，总浏览量达2000万</p>
                        </li>
                        <li>
                            <span></span>
                            <p>现任来飞网首席讲师，曾为绿茶、小蓝杯、喜茶、外婆家等多家大品牌餐饮企业做市场营销咨询</p>
                        </li>
                    </ul>
                </div>
            </div>


            <van-cell>
                <template slot="title">
                    <span style="font-size: 20px;font-weight: bold;">课程大纲</span>
                    <img src="../../assets/img/clip.png" style="height: 14px;margin-left: 5px;" alt="">
                </template>
            </van-cell>
            <div class="main_point">
                <p v-for="item in contents"><span>{{item.title}}</span>{{item.subtitle}}</p>
            </div>
            <div style="background: #fff;width: 100%;height: 30px;"></div>
        </div>
        <van-submit-bar
                :price="source.price"
                button-text="立即购买"
                text-align="left"
                button-type="warning"
                label="  "
                @submit="pay"
                class="buy_button"
        >
        </van-submit-bar>
        <van-action-sheet v-model="phoneSheet">
            <p style="font-size:16px;text-align:center;font-weight:bold;">为保障您的权益，请验证手机号</p>
            <van-cell-group>
                <van-field v-model="phoneNumber" label="手机号" placeholder="请输入手机号" :error-message="errMessage" />
                <van-field v-model="sms" center clearable label="短信验证码" placeholder="请输入短信验证码">
                    <van-button slot="button" v-show="!counting" size="small" color="linear-gradient(to top, #e4c491, #e3ac5b)" type="primary" @click="sendMessage">发送验证码</van-button>
                    <van-button slot="button" v-show="counting" disabled size="small">
                        <van-count-down ref="countDown" :auto-start="false" :time="60 * 1000" format="sss"  @finish="finish"/>
                    </van-button>
                </van-field>
                <div style="margin:0 auto;">
                    <van-button size="small" style="display:block;width:40%;margin:25px auto;" color="linear-gradient(to top, #e4c491, #e3ac5b)" @click="pay">确定</van-button>
                </div>
            </van-cell-group>
        </van-action-sheet>
    </div>
</template>

<script>
    import {Dialog,Toast} from 'vant';
    import wx from 'weixin-js-sdk';
    import {getQueryVariable} from "../../utils/public";
    export default {
        name: "CourseBuy",
        data() {
            return {
                course_id: 0,
                source:{},
                contents:[],

                require_phone: true,
                phoneSheet: false, // 验证手机号弹框
                phoneNumber: '', // 手机号
                sms: '', // 短信验证码
                counting: false,
                errMessage:'',
                phoneNumberCheck: false,
                permitToPay: false,
            }
        },
        mounted() {
            if(this.$route.query.course_id){
                this.course_id = this.$route.query.course_id;
            } else {
                this.course_id = getQueryVariable('course_id');
            }
            if(!this.course_id){
                Dialog({message:'未找到课程'}).then(()=>{ this.$router.replace('/')});
                return;
            }

            this.loadData();
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        },
        watch:{
            phoneNumber(newValue){
                if(this.phoneNumberCheck){
                    if(newValue.length === 11){
                        this.errMessage = '';
                    } else {
                        this.errMessage = '手机号格式错误';
                    }
                }
            }
        },
        methods: {
            loadData(){
                let that = this;
                this.post('/api/client/course/buy/before',{course_id:this.course_id}).then(result=> {
                    if(result.code === 0){
                        that.source = result.data.source;
                        that.contents = result.data.contents;
                        that.require_phone = result.data.require_phone;
                    } else {
                        Dialog({ message: result.msg });
                        that.$router.replace('/');
                    }
                }).catch(e=>{
                    console.log(e);
                    Dialog({ message: '课程信息加载失败' });
                    that.$router.replace('/');
                });
            },

            // 发送验证短信
            sendMessage(){
                // 验证手机号是否已填写
                if(this.phoneNumber.length !== 11){
                    this.phoneNumberCheck = true;
                    this.errMessage = '手机号格式错误';
                    Toast('请填写正确的手机号');
                    return;
                }
                if( this.errMessage ){
                    Toast('请填写正确的手机号');
                    return;
                }
                // 验证通过，发送短信
                this.post('/api/client/common/message/send',{phone:this.phoneNumber}).then(result=>{
                    if(result.code === 0){
                        Toast.success(result.msg);
                        this.permitToPay = true;
                        this.counting = true;
                        this.$refs.countDown.start();
                    } else {
                        Toast.fail(result.msg);
                    }
                })
            },
            // 倒计时结束
            finish(){
                this.counting = false;
                this.$refs.countDown.reset();
            },
            pay(){
                Dialog.alert({
                    title:'提示',
                    message:'暂时不允许购买',
                });
                return;
                // 生成订单
                let that = this;

                // 如果手机号未认证,打开验证手机的菜单
                if(this.require_phone){
                    if(this.phoneSheet){ // 如果已经打开了验证手机菜单，验证验证码是否已经输入完成
                        // 验证验证码是否正确输入
                        if(!this.permitToPay){
                            Toast('请先发送验证码');
                            return;
                        }
                        if(!(this.sms && this.sms.length === 6)){
                            Toast('请输入6位验证码');
                            return;
                        }
                    } else {
                        // 打开验证菜单
                        this.phoneSheet = true;
                        return;
                    }
                }

                // 生成订单
                let params = {type: 2, outer_id: this.course_id};
                if(this.require_phone){
                    params.code = this.sms;
                }
                this.post('/api/client/course/pay',params).then(result=>{
                    if(result.code === 0){
                        that.phoneSheet = false;
                        // 订单生成成功，支付
                        wx.chooseWXPay({
                            timestamp: result.data.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                            nonceStr: result.data.nonceStr, // 支付签名随机串，不长于 32 位
                            package: result.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                            signType: result.data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                            paySign: result.data.paySign, // 支付签名
                            success: function (res) {
                                Toast.success('购买成功');
                                // 支付成功后的回调函数
                                setTimeout(function(){
                                    // 支付成功后的回调函数
                                    that.$router.replace({path:'/courseDetail', query: { course_id: that.course_id }});
                                },2000)
                            }
                        });
                    } else {
                        Toast.fail(result.msg);
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>